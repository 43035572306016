.content-container {
    margin: 0 auto;
    align-items: center;
}
h2 {
    color: #4ec0e3;
    text-align: center;
    padding: 20px;
}

.card-img-top  {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-height:400px;
    width: auto;
    height: auto;
    text-align: center;
}
.bezienswaardigheidscard {
    position: relative;
    margin: 10px;
    padding: 5px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    /* Hover styles */
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    /* Animation for card entry */
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in-out forwards;

    /* Adjust the animation delay for each card */
    &:nth-child(odd) {
        animation-delay: 0.2s;
    }

    &:nth-child(even) {
        animation-delay: 0.4s;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}