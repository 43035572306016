.content-container {
    margin: 0 auto;
    align-items: center;
}
h2 {
    text-align: center;
    padding: 20px;
}

.infoItem img {
    margin:  0;
    padding: 0;
}
.appartement-item h4 {
    color: #4ec0e3;
    margin: 5px 20px;
    padding: 0 15px;


    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in-out forwards;


    &:nth-child(1) {
        animation-delay: 0.4s;
    }

    &:nth-child(2) {
        animation-delay: 0.6s;
    }

    &:nth-child(3) {
        animation-delay: 0.8s;
    }

    &:nth-child(4) {
        animation-delay: 1.0s;
    }

    &:nth-child(5) {
        animation-delay: 1.2s;
    }

    &:nth-child(6) {
        animation-delay: 1.4s;
    }

    &:nth-child(7) {
        animation-delay: 1.6s;
    }

    &:nth-child(8) {
        animation-delay: 1.8s;
    }

    &:nth-child(9) {
        animation-delay: 2.0s;
    }
}
.infoItem p {
    margin: 0;
}
.appartement-item .infoItem {
    position: relative;
    margin: 0 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    /* Hover styles */
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    /* Animation for prijzen-item entry */
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in-out forwards;

    /* Animation delay based on index */
    &:nth-child(1) {
        animation-delay: 0.2s;
    }

    &:nth-child(2) {
        animation-delay: 0.4s;
    }

    &:nth-child(3) {
        animation-delay: 0.6s;
    }

    &:nth-child(4) {
        animation-delay: 0.8s;
    }

    &:nth-child(5) {
        animation-delay: 1.0s;
    }

    &:nth-child(6) {
        animation-delay: 1.2s;
    }

    &:nth-child(7) {
        animation-delay: 1.4s;
    }

    &:nth-child(8) {
        animation-delay: 1.6s;
    }

    &:nth-child(9) {
        animation-delay: 1.8s;
    }
}

.padding-after-content {
    margin-bottom: 30px;
}