.content-container {
    margin: 0 auto;
    align-items: center;
}
h2 {
    text-align: center;
    padding: 20px;
}

/*.prijzen-item {*/
/*    border: 1px solid #ccc;*/
/*    padding: 10px;*/
/*    margin-bottom: 15px;*/
/*    background-color: #f9f9f9;*/
/*    opacity: 0;*/
/*    animation: fadeIn 0.5s ease-in-out forwards;*/
/*}*/

.prijzen-item p {
    margin: 0;
    font-weight: bold;
}

.indented {
    padding-left: 30px;
    font-size: 9.0pt;
    margin-top: 5px;
}

.prijzen-item {
    position: relative;
    margin: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    /* Hover styles */
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    /* Animation for prijzen-item entry */
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in-out forwards;

    /* Animation delay based on index */
    &:nth-child(1) {
        animation-delay: 0.2s;
    }

    &:nth-child(2) {
        animation-delay: 0.4s;
    }

    &:nth-child(3) {
        animation-delay: 0.6s;
    }

    &:nth-child(4) {
        animation-delay: 0.8s;
    }

    &:nth-child(5) {
        animation-delay: 1.0s;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}