@media (max-width: 991px) {
    .navbar-nav {
        list-style: none;
        display: flex;
        align-items: center;
    }
    .navbar  {
        justify-content: center;
        padding: 0;
        width: 100%;
        font-family: Roboto, serif;
        background-color: #fff !important;
    }
}

@media (max-width: 370px) {
    .logo {
        max-width: 100%;
        height: auto;
    }
    .navbar-brand {
        margin: 0;
    }
    .navbar-toggler {
        width: 100%;
        outline: none; /* Remove the outline on focus */
    }
}

.navbar {
    padding: 0;
    width: 100%;
    font-family: Roboto, serif;
    background-color: #fff !important;
}

.navbar-brand {
    padding: 0;
}

.ocean-wave {
    width: 100%;
    height: 50px; /* Adjust the height as needed */
    background: linear-gradient(0.25turn, #fdb913, #ebf8e1, #4ec0e3);
}

.navbar-brand a img {
    width: 300px;
}

.nav-list {
    margin: 0;
    padding: 0;
    align-items: center;
}

.nav-item {
    margin-right: 20px;
    font-size: 18px;
}

.nav-link {
    text-decoration: none;
    color: #3482b9; /* Light blueish text */
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    transition: color 0.2s;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #3482b9; /* Line color */
    transition: width 0.2s;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.nav-link:hover::after {
    width: 100%;
}