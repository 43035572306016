#map {
    height: 400px; /* The height is 400 pixels */
    width: 100%; /* The width is the width of the web page */
}

.contact-container {
    padding: 10px;
    margin: 0 auto;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.contact-form {
    padding-bottom: 20px;
}

.map {
    width: 100%;
    height: 600px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

