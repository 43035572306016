
@media (min-width: 1201px) {
    .foto-item {
        width: 80%;
        margin-left: 10%;
    }
}

@media (max-width: 1200px) {
    .foto-item {
        margin-left: 0;
        width: 100%;
    }
}

/*.fotoCard {*/
/*    border: 1px solid #ccc;*/
/*    padding: 10px;*/
/*    margin-bottom: 15px;*/
/*    background-color: #f9f9f9;*/
/*    opacity: 0;*/
/*    animation: fadeIn 0.5s ease-in-out forwards;*/
/*}*/

.fotoCard {
    border: 2px solid #ddd;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    /* Hover styles */
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    /* Animation for fotoCard entry */
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in-out forwards;

    /* Adjust the animation delay for each fotoCard */
    &:nth-child(1) {
        animation-delay: 0.2s;
    }

    &:nth-child(2) {
        animation-delay: 0.4s;
    }

    &:nth-child(3) {
        animation-delay: 0.6s;
    }

    &:nth-child(4) {
        animation-delay: 0.8s;
    }

    &:nth-child(5) {
        animation-delay: 1.0s;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.foto-item {
    margin-bottom: 15px !important;
}


.content-container {
    margin: 0 auto;
    align-items: center;
}
h4 {
    color: #4ec0e3;
}
h2 {
    color: #4ec0e3 !important;
    text-align: center;
    padding: 20px;
}

.img-thumbnail {
    transition: transform 0.3s ease-in-out;
}

.img-thumbnail:hover {
    transform: scale(1.05);
}