.custom-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-spinner span {
    margin-top: 0.5rem; /* Adjust the spacing between spinner and text */
}

.custom-spinner::before {
    content: '';
    width: 3rem; /* Adjust the size as needed */
    height: 3rem; /* Adjust the size as needed */
    border: 0.25em solid transparent;
    border-top-color: #fdb913; /* Set the top border color */
    border-bottom-color: #9fd0ee; /* Set the bottom border color */
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

.loading-text {
    color: #4ec0e3;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}
