.content-container {
    margin: 0 auto;
    align-items: center;
}

.section {
    padding: 20px;
}
.img-section {
    margin: auto;
}
.image-edit {
    width: 100%;
    height: auto;
}

/* Style the text container to constrain its width */
.text-container {
    text-align: center; /* Center the text */
    margin-top: 10px; /* Add some space between the image and text */
}

/* Style the text */
.text-container p {
    font-style: italic;
}

.text-section {
    text-align: left;
}

h2 {
    color: #4ec0e3;
}

.details-section {
    margin-top: 20px;
}

.details-section a {
    color: #4ec0e3;
}

.home {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}